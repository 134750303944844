<template>
  <div class="fullwidth tags-scoring pa-3">
    <div class="score-clock-title">
      Current Types Score
    </div>
    <div class="flex m-display-block align-flex-end space-between mb-4 mt-2">
      <gl-input
        v-model="search"
        class="mr-4 fullwidth"
        clearable
        data-cy="typeName"
        :height="40"
        label="TYPE NAME"
        name="type"
        placeholder="Search by a type name"
        @clear="resetSearch"
      />
      <button
        class="gl-button gl-button--dark gl-button--padder action-btn m-fullwidth m-mt-3"
        data-cy="addTypeSearch"
        @click="loadData(true)"
      >
        search
      </button>
    </div>
    <div class="table-wrap table-wrap__unoverflow gl-table-unshadow">
      <o-table
        backend-sorting
        class="main-table-wrap"
        :class="{ 'o-table-has-pagination': typesData.length && totalPages > 1 }"
        :data="typesData"
        :default-sort="[sortField, sortOrder]"
        :default-sort-direction="defaultSortOrder"
        hoverable
        :loading="loading"
        :mobile-cards="false"
        sort-icon="chevron-up"
        @sort="onSort"
      >
        <o-table-column
          v-slot="props"
          field="name"
          label="type name"
          sortable
        >
          {{ props.row.name }}
        </o-table-column>
        <o-table-column
          v-if="withDefScore"
          v-slot="props"
          field="defScore"
          label="Default score"
          sortable
        >
          <div v-if="props.row.defScore === undefined">
            <GlScoreLevelChip
              v-if="!loading"
              :level="props.row.score ? props.row.score.toFixed(0) : 0"
              score
            />
          </div>

          <GlScoreLevelChip
            v-else-if="!loading"
            :level="props.row.defScore ? props.row.defScore.toFixed(0) : 0"
            score
          />
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="score"
          label="Assigned Score"
          sortable
          :visible="canEditScore && canResetScore"
        >
          <div v-if="props.row.defScore === undefined">
            --
          </div>
          <GlScoreLevelChip
            v-else-if="!loading"
            :level="props.row.score ? props.row.score.toFixed(0) : 0"
            score
          />
        </o-table-column>
        <o-table-column
          v-slot="props"
          align="right"
          field="actions"
          label="Actions"
          :visible="canEditScore && canResetScore"
        >
          <div class="flex">
            <div style="min-width: 30px">
              <gl-icon
                v-tooltip.top="'Edit Type'"
                class="pointer"
                :height="24"
                name="edit-action"
                :width="24"
                @click="editType(props.row.name, Number(props.row.score), props.row.status, props.row._id)"
              />
            </div>
            <div>
              <gl-icon
                v-tooltip.top="'Reset Score'"
                class="pointer mr-2"
                :height="24"
                name="reload"
                :width="24"
                @click="resetScoreHandler(props.row)"
              />
            </div>
          </div>
        </o-table-column>
        <template #empty>
          <div
            v-if="loading"
            class="flex align-center justify-center"
          >
            <gl-loader />
          </div>
          <div
            v-else
            class="empty-users-data flex column align-center"
          >
            <gl-icon
              :height="24"
              name="statistic"
              :width="24"
            />
            No data here yet
          </div>
        </template>
      </o-table>
      <div class="flex space-between pa-1 m-pa-0 m-pt-3">
        <div class="flex m-display-block align-center pa-3 m-pa-0">
          <div class="mr-2 m-mr-0 m-mb-2 fs-14 bold">
            Rows per page:
          </div>
          <vSelect
            v-model="perPage"
            class="stat-select__pagination mr-1"
            :clearable="false"
            :options="pagesOptions"
            :searchable="false"
            @input="countChange"
          />
        </div>
        <o-pagination
          v-if="typesData.length && totalPages > 1"
          class="stat-pagination"
          :current.sync="currentPage"
          order="right"
          :per-page="perPage"
          simple
          :total="total"
          @change="pageChange"
        >
          <o-pagination-button
            slot="previous"
            slot-scope="props"
            :page="props.page"
          >
            <gl-menu-item
              class="change-page mr-2"
              :disabled="props.page.disabled"
              icon="left"
              :icon-height="24"
              :icon-width="24"
              label=""
              not-outline
            />
          </o-pagination-button>

          <o-pagination-button
            slot="next"
            slot-scope="props"
            :page="props.page"
          >
            <gl-menu-item
              class="change-page"
              :disabled="props.page.disabled"
              icon="right"
              :icon-height="24"
              :icon-width="24"
              label=""
              not-outline
            />
          </o-pagination-button>
        </o-pagination>
      </div>
    </div>
    <EditTypeModal
      v-if="showEditModal"
      :id="editTypeId"
      v-model="showEditModal"
      :loading="loading"
      :score="editTypeScore"
      :status="editTypeStatus"
      :type="editTypeName"
      @change-score="changeScore"
      @close="showEditModal = false"
      @edit="updateType"
    />
    <confirmUpdateCalcModal
      v-if="showConfirmModal"
      v-model="showConfirmModal"
      :message="message"
      @close="showConfirmModal = false"
      @confirm="confirm"
    />
    <resetScoreModal
      v-if="showResetModal"
      v-model="showResetModal"
      :name="resetData.name"
      @close="showResetModal = false"
      @confirm="resetConfirm"
    />
  </div>
</template>

<script>
//Vuex
import {mapActions, mapState} from "vuex";

// Components
import vSelect from 'vue-select'
import GlIcon from '@/components/gl-icon'
import GlLoader from '@/components/gl-loader'
import GlMenuItem from '@/components/gl-menu-item'
import EditTypeModal from '../modals/EditTypeModal'
import GlScoreLevelChip from '@/components/gl-score-level-chip'
import resetScoreModal from "@/pages/scoring-profiles/modals/resetScoreModal.vue";
import confirmUpdateCalcModal from "@/pages/scoring/modals/confirmUpdateCalcModal";
import GlInput from "@/components/gl-input.vue";

// Utils
import { formatterRecalcApproveMessage } from "@/utils/text-formatter";
import appConfig from '@/utils/appConfig'

export default {
  name: 'TypesScoring',
  components: {
    GlInput,
    GlIcon,
    vSelect,
    GlLoader,
    GlMenuItem,
    EditTypeModal,
    resetScoreModal,
    GlScoreLevelChip,
    confirmUpdateCalcModal,
  },
  props: {
    profileId: {
      type: [Number, String],
      default: null
    },
    withDefScore: {
      type: Boolean,
      default: false
    },
    canEditScore: {
      type: Boolean,
      default: true
    },
    canResetScore: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      showConfirmModal: false,
      resetData: null,
      message: '',
      loading: false,
      pagesOptions: [5, 10,20, 50, 100],
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      total: 1,
      defaultSortOrder: 'desc',
      sortField: 'name',
      sortOrder: 'desc',
      name: '',
      search: '',
      score: 0,
      allTypes: [],
      typesData: [],
      editTypeName: '',
      newType: '',
      newScore: 0,
      editTypeScore: 0,
      editTypeId: null,
      editTypeStatus: '',
      showEditModal: false,
      showResetModal: false,
      skipCheck: false,
    }
  },
  computed: {
    ...mapState('analytics', ['coinType', 'currencyList', 'coinData']),
    appConfig() {
      return appConfig
    },
  },
  watch: {
    coinType: {
      handler() {
        this.loadData()
      },
      immediate: true
    },
    profileId: {
      handler() {
        this.loadData()
      },
      immediate: true
    }
  },
  created() {
    this.perPage = localStorage.getItem('type-scoring-per-page') || this.perPage
    localStorage.setItem('type-scoring-per-page', this.perPage)
  },
  async mounted() {
    await this.loadData()
     this.getTypes({ count: 1000 }).then(({ data: { typeData } }) => {
       this.allTypes = typeData?.map(type => type.name)
     })
  },
  methods: {
    formatterRecalcApproveMessage,
    ...mapActions({
      getTypes: 'types/getTypes',
      editTypesData: 'types/editType',
      resetScoreType: 'types/resetScoreType',
    }),
    resetScoreHandler(data) {
      this.showResetModal = true
      this.resetData = data
    },
    resetConfirm(name) {
      const requestedCoins = appConfig.VUE_APP_MULTI_LABELING ? this.currencyList : [this.coinData]

      requestedCoins.forEach((coin) => {
        this.resetScoreType({ name, profileId: this.profileId, id: this.resetData._id, baseUrl: coin.profilesServiceUrl }).then((success, data) => {
          if (success) {
            this.showResetModal = false
            this.loadData()
          } else {
            this.$toasted.global.error({ message: `${data.message}`})
          }
        })
      })
    },
    confirm() {
      this.showEditModal = false
      this.skipCheck = true
      this.updateType(this.newType, this.newScore, this.editTypeId)
    },
    forbidEdit(type) {
      if (!type.status) return false
      return type.status && type.status.includes('forbidEdit')
    },
    forbidDeleteAndRename(type) {
      if (!type.status) return false
      return type.status.includes('forbidDeleteAndRename')
    },
    handleScoring(score) {
      if (score && Number(score) > 100) {
        this.score = 100
      }
    },
    resetSearch() {
      this.search = ''
      this.loadData()
    },
    changeScore(score) {
      this.editTypeScore = score
    },
    editType(name, score, status = '', id) {
      this.editTypeName = name
      this.editTypeId = id
      this.editTypeScore = Number(score)
      this.editTypeStatus = status
      this.showEditModal = true
    },
    updateType(name, score, id) {
      this.message = ''
      this.newType = ''
      this.newScore = 0
      this.loading = true

      const requestedCoins = appConfig.VUE_APP_MULTI_LABELING ? this.currencyList : [this.coinData]

      requestedCoins.forEach((coin) => {
        this.editTypesData({ name: this.editTypeName, newName: name, score: Number(score), skipCheck: this.skipCheck, profileId: this.profileId, isLabeling: false, id, baseUrl: coin.profilesServiceUrl }).then(({ success, data }) => {
          if (success) {
            this.editTypeName = ''
            this.editTypeScore = ''
            this.showEditModal = false
            this.showConfirmModal = false
            this.skipCheck = false
            this.loadData()
          } else {
            this.newType = name
            this.newScore = score
            if (data.showPopup || data?.response?.messageData) {
              this.message = this.formatterRecalcApproveMessage ? this.formatterRecalcApproveMessage(data?.response?.messageData) : data.message
              this.showConfirmModal = true
            } else this.$toasted.global.error({ message: `${data.message}`})
          }
        }).finally(() => {
          this.loading = false
        })
      })
    },
    loadData(isNewSearch = false) {
      if (isNewSearch) {
        this.currentPage = 1
      }
      this.$emit('loading', this.loading)
      this.loading = true
      const sendParams = this.formattedSendData()
      this.getTypes(sendParams).then(({ data: { total, typeData } }) => {
        this.typesData = typeData || []
        this.total = total
        this.totalPages = Math.ceil(this.total / this.perPage)
        this.loading = false
        this.$emit('loading', this.loading)
      })
    },
    onSort(field, order) {
      this.sortOrder = order
      this.sortField = field
      this.loadData()
    },
    formattedSendData() {
      this.perPage = localStorage.getItem('type-scoring-per-page') || this.perPage

      return {
        sortField: this.sortField || undefined,
        count: this.perPage,
        search: this.search,
        profileId: this.profileId,
        skip: (this.currentPage - 1) * this.perPage,
        isLabeling: true,
        sortOrder: this.sortOrder || undefined
      }
    },
    countChange() {
      localStorage.setItem('type-scoring-per-page', this.perPage)
      this.loadData()
    },
    pageChange(event) {
      this.currentPage = event
      this.loadData()
    },
  }
}
</script>

<style>
.tags-scoring {
  background: #fff;
}

.score-clock-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 40px;
}

.change-page {
  border: 1px solid var(--dark-grey-d-3);
  border-radius: 3px;
  height: 35px;
  padding-top: 5px;
}

.stat-select {
  width: 160px;
}

.stat-select .vs__dropdown-toggle {
  height: 40px !important;
  background: #fff;
  border: none;
}

.stat-select__pagination{
  width: 150px;
}

.stat-select__pagination .vs__dropdown-toggle{
  background: #fff;
  border: 1px solid #f5f5f5 !important;
  height: 35px;
}

.statistic-table .o-table .o-table__th {
  font-size: 12px;
}

.action-btn {
  height: 40px;
  width: 165px;
}

.tags-scoring {
  border-radius: 3px;
}

@media (max-width: 767px) {
  .tags-scoring .o-pag {
    max-width: 96px;
    padding: 24px 0 0 0;
  }
  .tags-scoring .o-pag__link {
    margin: 0;
    padding: 0;
  }
  .tags-scoring .o-pag__info {
    min-width: 100%;
    padding-top: 8px;
  }
  .tags-scoring .o-table__mobile-sort {
    margin-bottom: 16px;
  }
}
</style>
